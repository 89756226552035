<div class="container-fluid" style='padding: 5px 35px;'>
  <div>
    <div class='text-center'>
      <img style='margin:auto auto; height: 150px' class="img-fluid rounded-circle" src='assets/images/pic.png'>
    </div>
    <h3 class='text-info text-center'> Digender Mahara</h3>
    <h6 class='text-center text-lg'>Frontend Developer</h6>
    <hr style='background: #555;'>
    <div class='row text-primary text-center text-md'>
      <div class='col-sm-4'><i class='fa fa-mobile'></i><a href="tel:+918879469792"
          class='m-l-xs'>+91-887&nbsp;946&nbsp;9792</a></div>
      <div class='col-sm-4'><i class='fa fa-envelope-o'></i><a href="mailto:mdigender@gmail.com"
          class='m-l-xs'>mdigender@gmail.com</a></div>
      <div class='col-sm-4'><i class='fa fa-globe'></i><a target='_blank' href="https://digender.info"
          class='m-l-xs'>digender.info</a></div>
    </div>
    <div class='text-center text-lg' style="padding: 10px 5px;"><b>Frontend developer with experience in both
        frontend & backend technologies with an experience of {{yearsOfExperience}} years.</b></div>
    <!-- Main CV starts here -->
    <div class='row'>
      <div class='col-sm-8' style='padding: 20px 12px;'>
        <h5 class='text-xlg'><i class='fa fa-users'></i><span class='m-l-xs'>WORK EXPERIENCE</span></h5>
        <hr />
        <div class='main'>
          <div class='circle' style='position: absolute;left: 2px;'></div>
          <b class='text-primary'>SIRIUS - Morgan Stanley (Client)</b> - <em>[Teamware Solutions (Vendor)]</em><span class='text-sm m-l-xs'>(Nov 2018 to Present)</span>
          <div class='text-md'>
            <b>Frontend Developer</b>
            <ul>
              <li>Gathering requirements from Users and Designing UI/UX accordingly.</li>
              <li>Working on UI integration with REST APIS, using Angular 7 framework.</li>
              <li>Creating custom Components and Directives</li>
              <li>Unit Testing code with Jasmine and Karma</li>
              <li>End to end testing with Protractor - Gherkins - Typescript</li>
              <li>Deploying project with continuous integration tools like Jenkins</li>
            </ul>
          </div>
        </div>
        <div class='main'>
          <div class='circle' style='position: absolute;left: 2px;'></div>
          <b class='text-primary'>QuezX Hire 2X Faster </b> - <em>[Quetzal Online Pvt Ltd]</em><span class='text-sm m-l-xs'> (Oct 2016 to Nov 2018)</span>
          <div class='text-md'>
            <b>Fullstack Developer</b>
            <ul>
              <li>Working on UI integration using Angular JS framework.</li>
              <li>Developing REST APIs using express framework.</li>
              <li>Developing Middlewares for Authentication.</li>
              <li>Building Projects using Task Managers like Gulp and Grunt and deploying on Cloud Servers like
                Digital Ocean and Linode.</li>
              <li>Using SES of AWS for Email Templating.</li>
              <li>Interviewed prospective employees and mentored junior software developers on the team.</li>
              <li>Discussed, analyzed and strategized product design with Product Managers, UX team and Business
                Managers.</li>
            </ul>
          </div>
        </div>
        <div class='main'>
          <div class='circle' style='position: absolute;left: 2px;'></div>
          <b class='text-primary'>PayVUS - Aurion Pro Solutions (Client)</b> - <em>[Kuchnaya Technolabs Pvt Ltd (Vendor)]</em><span class='text-sm m-l-xs'>(Oct 2015 to Oct 2016)</span>
          <div class='text-md'>
            <b>Front End Developer</b>
            <ul>
              <li>Working on UI integration using Angular JS framework.</li>
              <li>Consuming REST apis with Angular JS.</li>
              <li>Constructing UI using Bootstrap.</li>
            </ul>
          </div>
        </div>
        <div class='main'>
          <div class='circle' style='position: absolute;left: 2px;'></div>
          <b class='text-primary'>MyEffecto</b> - <em>[Kuchnaya Technolabs Pvt Ltd]</em><span class='text-sm m-l-xs'>(Apr 2015 to Oct 2015)</span>
          <div class='text-md'>
            <b>Fullstack Developer</b>
            <ul>
              <li>Creating widgets for feedback like quick comment feedback, sharing blog posts on various social
                media platforms.</li>
              <li>Created a single page configuration screen of wordpress using Knockout JS framework that allowed the
                user create and edit the plugin at the same time.</li>
              <li>Used Google App Engine (PaaS) as backend technology.</li>
            </ul>
          </div>
        </div>
        <div class='main'>
          <div class='circle' style='position: absolute;left: 2px;'></div>
          <b class='text-primary'>HootOut</b>
          - <em>[Kuchnaya Technolabs Pvt Ltd]</em>
          <div class='inline m-l-xs text-sm'>(Dec 2014 to Apr 2015)</div>
          <div class='text-md'>
            <b>Fullstack Developer</b>
            <ul>
              <li>HootOut readymade templates.</li>
              <li>HootOut Trending articles modules.</li>
            </ul>
          </div>
        </div>
        <h5 class='text-xlg'><i class='fa fa-mortar-board'></i><span class='m-l-xs'>EDUCATION</span></h5>
        <hr />
        <div class='text-md'>MCA from Mumbai University - Year (2012 - 2015) - 8.16 cbgs</div>
        <div class='text-md'>BSc I.T from Mumbai University - Year (2009 - 2012) - 69.80%</div>
      </div>
      <div class='col-sm-4' style='padding: 20px 12px;'>
        <h5 class='text-xlg'><i class='fa fa-cog'></i><span class='m-l-xs'>TECHNICAL SKILLS</span></h5>
        <hr />
        <table class='rating text-md' style='width: 100%;'>
          <tr>
            <td>Javascript</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>Angular</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>Node JS/Express</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star-half-full m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>MySQL</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star-half-full m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>Bootstrap</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>JQuery</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>CSS/SASS</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>Jenkins</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='fa fa-star-o m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
          <tr>
            <td>Webpack</td>
            <td><i class='active fa fa-star m-l-xs'></i><i class='active fa fa-star m-l-xs'></i><i
                class='active fa fa-star m-l-xs'></i><i class='fa fa-star-o m-l-xs'></i><i
                class='fa fa-star-o m-l-xs'></i></td>
          </tr>
        </table>
        <h5 style='margin-top: 25px;'><i class='fa fa-wrench'></i><span class='m-l-xs text-xlg'>OTHER TECHNOLOGIES KNOWN</span></h5>
        <hr />
        <p>React JS, Flutter</p>
        <h5 style='margin-top: 25px;'><i class='fa fa-user-circle-o'></i><span class='m-l-xs text-xlg'>PERSONAL
            DETAILS</span></h5>
        <hr />
        <table class='rating text-md' style='width: 100%;'>
          <tr>
            <td style='width: 30%;'>Date of Birth</td>
            <td>May 1991</td>
          </tr>
          <tr>
            <td>Marital Status</td>
            <td>Married</td>
          </tr>
          <tr>
            <td>Languages Known</td>
            <td>English, Hindi, Marathi, Kumaoni(Mother Tongue)</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>Sarvoday Swaroop, Telkoswadi, Old Dombivli, Dombivli West - 421202</td>
          </tr>
        </table>
        <h5 style='margin-top: 25px;'><i class='fa fa-grav'></i><span class='m-l-xs text-xlg'>Hobbies</span></h5>
        <hr />
        <div class='row text-center'>
          <div class="col-sm-3 col-xs-6">
            <div><img src='assets/images/guitar.png' /></div>
            <div>Playing Guitar</div>
          </div>
          <div class="col-sm-3 col-xs-6">
            <div><img src='assets/images/gaming.png' /></div>
            <div>Gaming</div>
          </div>
          <div class="col-sm-3 col-xs-6">
            <div><img src='assets/images/trekkking.png' /></div>
            <div>Treking</div>
          </div>
          <div class="col-sm-3 col-xs-6">
            <div><img src='assets/images/running.jpg' style='height: 60px;'></div>
            <div>Running</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='text-xs'>Source: <a
      href='https://github.com/Digender/portfolio'>https://github.com/Digender/portfolio</a></div>
</div>
