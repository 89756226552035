<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>

<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>

<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>

<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>

<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>
<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>
<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>
<div class="first-grid">
  <div class="grid-items text-center">1</div>
  <div class="grid-items text-center">2</div>
  <div class="grid-items text-center">3</div>
  <div class="grid-items text-center">4</div>
  <div class="grid-items text-center">5</div>
  <div class="grid-items text-center">6</div>
  <div class="grid-items text-center">7</div>
  <div class="grid-items text-center">8</div>
  <div class="grid-items text-center">9</div>
</div>
