<div class="home">
  <div class="sidebar">
    <div class="img-holder text-center">
      <img src="../../assets/images/pic.png">
    </div>
  </div>
  <div class="display">
    <app-profile></app-profile>
  </div>
</div>

