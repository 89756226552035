<div class="profile">
  <div class="basic">
    <div class="info">
      <div class="img-holder text-center">
        <img src="../../assets/images/pic.png" style="border-radius: 50%;">
      </div>
      <h1>Digender Mahara</h1>
      <p>Frontend developer, with an experience of {{yearsOfExperience}} years</p>
      <div><a class='btn btn-md btn-primary'  routerLink="/cv">Curriculum Vitae</a></div>
    </div>
  </div>
</div>
